import React from 'react';

import { Layout, SEO } from 'components';
import { NewPatients } from 'containers';

const NewPatientsPage = () => (
  <Layout>
    <SEO title='Information for new patients' />
    <NewPatients />
  </Layout>
);

export default NewPatientsPage;
